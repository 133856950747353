import { Box, Button, Divider } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { Yup, components, constants, useServices } from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'
import Api from '../../shared/api'
import ApproveBookingDialogContext from './ApproveBookingDialogContext.js'
import CancelButton from 'src/components/button/CancelButton.js'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls.js'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import check from 'check-types'
import * as calistaConstant from 'src/constants/CalistaConstant'
// import ValidationMessageTranslationText from 'src/views/freightbooking/shared/validation/ValidationMessageTranslationText'
import pathMap from 'src/paths/PathMap_FreightBooking'
import CommonUtils from 'src/views/common/utils/Utils'

const {
  form: {
    CngViewForm,
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    CngForm,
    field: {
      CngTextField
    },
  },
  table: { useDefaultNotification }
} = components
const { FormState } = constants

function ApproveBookingForm(props) {

  const { booking, approve, setBookingStatus, setAuditList, setUserCriteria } = props
  const { setFieldValue } = useFormikContext()
  const { createRecord, fetchRecords } = useServices()
  const dialogContext = useContext(ApproveBookingDialogContext)
  const {
    closeDialog,
    showNotification,
    form: { isSubmitting, setSubmitting }
  } = dialogContext
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)
  const translatedTextsObject = FreightBookingTranslationText()

  function onSubmit(data) {

    console.log('ApprovedForm onSubmit data: ' + JSON.stringify(data))
    props.booking.carrierRemarks = data.carrierRemarks
    props.booking.carrierRefNo = data.carrierRefNo
    console.log('ApprovedForm onSubmit booking: ' + JSON.stringify(props.booking))

    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
    
      if (response.errorMessages) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(`${approve ? translatedTextsObject.approveSuccess : translatedTextsObject.rejectSuccess}`)
       
        //refresh audit trail table
        if (response.freightBookingId) {
          Api.fetchAuditTrailListing(fetchRecords, response.freightBookingId, onAuditTrailListingLoadSuccess)
        }

        function onAuditTrailListingLoadSuccess(data) {
          data.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
       
          let userList = []
          data.forEach((val)=>{
            userList.push(val.userId)
          })
          setUserCriteria(userList)
          setAuditList(data)
          setBookingStatus(response.bookingStatus)
          setSubmitting(false)
          closeDialog()
        }

      }
    }

    function onError(error) {
      console.log('connection-request error', error.message)
      setSubmitting(false)
      showErrorNotification(CommonUtils.getErrorMessage(error))
    }

    setSubmitting(true)
    createRecord.execute(

      getSubmitURL(booking.bookingStatus),
      // FreightBookingApiUrls.APPROVE_BOOKING,
      props.booking,
      onSuccess,
      onError
    )


  }


  function getSubmitURL(bookingStatus) {

    if (approve) {

      return bookingStatus.toString() == calistaConstant.BOOKING_STATUS_PENDING_APPROVAL ?
        FreightBookingApiUrls.APPROVE_BOOKING :
        FreightBookingApiUrls.APPROVE_AMEND_BOOKING

    } else {

      return bookingStatus.toString() == calistaConstant.BOOKING_STATUS_PENDING_APPROVAL ?
        FreightBookingApiUrls.REJECT_BOOKING :
        FreightBookingApiUrls.REJECT_AMEND_BOOKING

    }
  }

  function FormBody({ closeDialog, isSubmitting, booking, approve }) {

    // const translatedTextsObject = FreightBookingTranslationText()
    const [, , { setValue: setCarrierRefNoField }] = useField('carrierRefNo')
    const [, , { setValue: setCarrierRemarksField }] = useField('carrierRemarks')
    const [, ,] = useField('approvalInd')

    useEffect(() => {
      setCarrierRefNoField(booking.carrierRefNo)
      setCarrierRemarksField(booking.carrierRemarks)

    }, [])

    return (
      <Box m={1.5}>
        <Box mb={2}>
          <Box className="view-card-content-label">
            {translatedTextsObject.shipperRemarks}
          </Box>
          <Box className="view-card-content-value">
            {booking.shipperRemarks}
          </Box>
        </Box>
        <Divider light />
        <Box my={2}>
          <CngTextField
            type='text'
            inputProps={{ maxLength: 35 }}
            label={translatedTextsObject.bookingRefenceNumber}
            name='carrierRefNo' 
            isRequired={approve}
            />
        </Box>
        <Box my={1}>
          <CngTextField
            type='text'
            inputProps={{ maxLength: 1024 }}
            label={translatedTextsObject.carrierRemarks}
            multiline
            rows={3}
            name='carrierRemarks' />
        </Box>
        <Box display='flex' flexDirection={'row-reverse'} mt={2}>
          <Box>
            <Button variant="contained"
              className="button-blue originalText"
              type="submit"
              disabled={isSubmitting}
            >
              {approve ? translatedTextsObject.approveBooking
                : translatedTextsObject.declineBooking}
              {/* {translatedTextsObject.approveBooking} */}
            </Button>
          </Box>
          <Box mr={1}>
            <CancelButton onClick={closeDialog} disabled={isSubmitting} />
          </Box>
        </Box>
      </Box>
    )
  }

  const initialValues = Object.freeze({
    carrierRemarks: "",
    carrierRefNo: "",
    approvalInd: approve
  })


  const validationSchema = Yup.object({
    carrierRefNo: Yup.string().when("approvalInd", {
      is: true,
      then: Yup.string().required('Required').nullable(),
      otherwise: Yup.string().nullable()
    }),
  })

  return (
    <CngViewForm
      formikProps={{
      initialValues: {  
        ...initialValues
      },
      validationSchema: validationSchema,
       onSubmit: onSubmit,
       validateOnChange: false
    }}
    bodySection={

      <FormBody closeDialog={closeDialog} isSubmitting={isSubmitting}
             booking={props.booking} approve={approve} />
    }
    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
  />
  )
}



export default ApproveBookingForm
