import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'

import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import ResultPageTablePagination from 'src/views/vesselschedule/searchschedule/ResultPageTablePagination'
import SearchScheduleTranslationText from 'src/views/vesselschedule/searchschedule/SearchScheduleTranslationText'
import moment from 'moment'
import searchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'

const { CngGridItem } = components

function AmendScheduleVesselScheduleTableComponent(props) {
  const { booking, setLoading, closeDialog } = props
  const { fetchRecords } = useServices()
  const [isLoading, setIsLoading] = useState(false)

  const translatedTextsObject = FreightBookingTranslationText()
  const vsTranslatedTextsObject = SearchScheduleTranslationText()

  const [columns, setColumns] = useState([
    { name: translatedTextsObject.vesselName },
    { name: translatedTextsObject.voyageNumber },
    { name: translatedTextsObject.internationalVoyageNumber },
    { name: vsTranslatedTextsObject.departPOL },
    { name: vsTranslatedTextsObject.arrivePOD },
    { name: vsTranslatedTextsObject.transitTime }
  ])

  const [shipperSchedules, setShipperSchedules] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const searchCriteria = {
    cargoReadyDate: booking.cargoReadyDate,
    cargoDeliveryDate: booking.cargoDeliveryDate,
    portOfLoading: booking.polCode,
    portOfDischarge: booking.podCode,
    searchType: 'DEFAULT',
    carrierSearch: true
    // searchType:
    //   location.state.preferred !== undefined
    //     ? location.state.preferred
    //     : 'DEFAULT',
    // progressive: true,
    // pollingId: location.state.pollingId,
    // weeks: location.state.weeks
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRowClick = (event, value) => {
    // console.log("handleCellClick")
    // console.log(value)

    closeDialog(value)
  }
  // let testData = [
  //   { vesselName: "Vessel 1", service: "JH172", voyageNo: "67GHR", directShipment: "true", transit: "10" },
  //   { vesselName: "Vessel 2", service: "TG873", voyageNo: "86JUI", directShipment: "false", transit: "7" },
  //   { vesselName: "Vessel 3", service: "IOP27", voyageNo: "PO123", directShipment: "falsestart", transit: "3" },
  //   { vesselName: "KOta Raya", service: "KJK12", voyageNo: "PO123", directShipment: "true", transit: "5" },
  //   { vesselName: "KOta Raya", service: "KJK12", voyageNo: "PO123", directShipment: "true", transit: "5" },
  //   { vesselName: "KOta Raya", service: "KJK12", voyageNo: "PO123", directShipment: "true", transit: "5" },
  //   { vesselName: "KOta Raya", service: "KJK12", voyageNo: "PO123", directShipment: "true", transit: "5" },
  //   { vesselName: "KOta Raya", service: "KJK12", voyageNo: "PO123", directShipment: "true", transit: "5" },
  //   { vesselName: "KOta Raya", service: "KJK12", voyageNo: "PO123", directShipment: "true", transit: "5" },
  //   { vesselName: "KOta Raya", service: "KJK12", voyageNo: "PO123", directShipment: "true", transit: "5" },
  //   { vesselName: "KOta Raya", service: "KJK12", voyageNo: "PO123", directShipment: "true", transit: "5" }
  // ]

  // const testData2 = () => {
  //   let temp = []
  //   for (var i = 0; i < 15; i++) {
  //     temp.push({
  //       vesselName: "KOta Raya" + i,
  //       service: i + "KJK12",
  //       voyageNo: "PO123",
  //       directShipment: "true",
  //       transit: "5",
  //       pod: "CNSHA",
  //       podEta: "2021-03-16T22:00:00.000+0000",
  //       podEtd: "2021-03-19T22:15:00.000+0000",
  //       pol: "SGSIN",
  //       polEta: "2021-03-12T06:00:00.000+0000",
  //       polEtd: "2021-03-12T18:00:00.000+0000",
  //       voyageId: i,
  //       transportMode: "V",
  //     })
  //   }

  //   return temp
  // }

  useEffect(() => {
    //setShipperSchedules(testData2)
    console.log('table on change')
    //setLoading(true)
    setIsLoading(true)
    fetchRecords.execute(
      searchScheduleApiUrls.CARRIER_VESSEL_SCHEDULE,
      {
        customData: searchCriteria
      },
      (data) => {
        // setLoading(false)
        setIsLoading(false)
        console.log(
          'shipperSchedules api data > ' + JSON.stringify(data.content)
        )
        setShipperSchedules(data.content)
      },
      (error) => {
        console.log(error)
        //  setLoading(false)
      }
    )
  }, [])

  return (
    <Grid container>
      <CngGridItem xs={12} sm={12}>
        <Grid xs={12} sm={9} shouldHide={isLoading ? false : true}>
          <CngBackdrop loading={isLoading} />
        </Grid>
      </CngGridItem>
      <CngGridItem
        xs={12}
        sm={12}
        shouldHide={!isLoading && shipperSchedules.length > 0 ? false : true}
        //shouldHide={shipperSchedules.length > 0 ? false : true}
      >
        <Card>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <div className={'rs-align-spacing-grey'}>
                <Typography variant='caption'>{`Results ${Math.min(
                  page * rowsPerPage + 1,
                  shipperSchedules.length
                )}-${Math.min(
                  (page + 1) * rowsPerPage,
                  shipperSchedules.length
                )} of ${shipperSchedules.length}`}</Typography>
              </div>
            </Grid>
          </Grid>
        </Card>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.name + index}
                    className={'vs-table-cell-padding'}
                  >
                    {column.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading && <h1> loading...</h1>}
              {console.log('shipperSchedules > ' + shipperSchedules)}
              {(rowsPerPage > 0 && shipperSchedules
                ? shipperSchedules.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : shipperSchedules
              ).map((shipperSchedule) => (
                <Fragment key={`Fragment-${shipperSchedule.voyageId}`}>
                  <TableRow
                    key={shipperSchedule.voyageId}
                    hover
                    onClick={(event) => handleRowClick(event, shipperSchedule)}
                  >
                    <TableCell
                      className={'vs-table-cell-padding'}
                      component='th'
                      scope='row'
                    >
                      <div className={'rs-showContent-bold'}>
                        {shipperSchedule.vesselName}
                      </div>
                      <div className={'rs-showContent-bold'}>
                        {shipperSchedule.service}
                      </div>
                    </TableCell>

                    <TableCell>{shipperSchedule.voyageNo}</TableCell>

                    <TableCell>
                      {shipperSchedule.internationalVoyageNumber}
                    </TableCell>

                    <TableCell>
                      {/* depart pol */}

                      <div className={'rs-showContent-bold'}>
                        {moment(shipperSchedule.polEta).format(
                          'D MMM (ddd) h:mm'
                        )}
                      </div>
                      <div className={'vs-result-content-down'}>
                        <span>
                          <Chip
                            label={<b>{shipperSchedule.pol}</b>}
                            size='small'
                          />
                        </span>
                      </div>
                    </TableCell>

                    <TableCell>
                      {/* arrival pod */}
                      <div className={'rs-showContent-bold'}>
                        {moment(shipperSchedule.podEta).format(
                          'D MMM (ddd) h:mm'
                        )}
                      </div>
                      <div className={'vs-result-content-down'}>
                        <span>
                          <Chip
                            label={<b>{shipperSchedule.pod}</b>}
                            size='small'
                          />
                        </span>
                      </div>
                    </TableCell>

                    <TableCell>
                      <div className={'rs-showContent-bold'}>
                        {shipperSchedule.transit} {vsTranslatedTextsObject.days}
                      </div>
                      {/* {shipperSchedule.directShipment ? ( */}
                      <div className={'rs-green'}>
                        {' '}
                        {vsTranslatedTextsObject.direct}
                      </div>
                      {/* ) : null} */}
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <ResultPageTablePagination
                  recordLength={shipperSchedules.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </CngGridItem>
    </Grid>
  )
}

export default AmendScheduleVesselScheduleTableComponent
