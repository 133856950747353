import moment from 'moment'
import { Box, Paper } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'

import AmendScheduleComponent from '../../components/AmendScheduleComponent'
import Api from '../../shared/api'
import ApprovalComponent from '../../components/ApprovalComponent'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CargoComponent from '../../components/CargoComponent'
import CngBackdrop from '../../../vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import ContactDetailsComponent from '../../components/ContactDetailsComponent'
import ContainerComponent from '../../components/ContainerComponent'
import DocumentComponent from '../../components/DocumentComponent'
import FrbUtils from '../../shared/Utils'
import FreightBookingTranslationText from '../../shared/FreightBookingTranslationText'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import ManageBookingAmendButtonComponent from './ManageBookingAmendButtonComponent'
import PaymentInstructionComponent from '../../components/PaymentInstructionComponent'
import ShipmentComponent from '../../components/ShipmentComponent'
import Utils from 'src/views/common/utils/Utils'
import ViewBookingTypeComponent from '../../components/ViewBookingTypeComponent'
import ViewCargoComponent from '../../components/ViewCargoComponent'
import ViewPaymentComponent from '../../components/ViewPaymentComponent'
import WarningDialog from 'src/components/dialog/WarningDialog'
import pathMap from 'src/paths/PathMap_FreightBooking'
import { v4 as uuid } from 'uuid'
import ViewDgCargoComponent from '../../components/ViewDgCargoComponent'
import ValidationMessageTranslationText from 'src/views/freightbooking/shared/validation/ValidationMessageTranslationText'
import QuickScroll from 'src/components/iconbutton/QuickScroll'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  saveDraft: false,
  amendBooking: true,
  bookingId: '',
  setFreightBookingId: '',
  bookingStatus: 5102,
  ...AmendScheduleComponent.initialValues,
  ...ShipmentComponent.initialValues,
  ...ContainerComponent.initialValues,
  ...ContactDetailsComponent.initialValues,
  ...PaymentInstructionComponent.initialValues,
  ...CargoComponent.initialValues,
  ...ApprovalComponent.initialValues
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function FormFields({ disabled, showNotification, loading }) {
  const { errors } = useFormikContext()
  const history = useHistory()
  const location = useLocation()
  const data = location.state

  const [booking, setBooking] = useState({})
  const fbTranslatedTextsObject = FreightBookingTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const validationMessageTranslation = ValidationMessageTranslationText()

  const [cargoReadyDateField, ,] = useField('cargoReadyDate')
  const [cargoDeliveryDateField, ,] = useField('cargoDeliveryDate')
  const SG_DATE_TIME_FORMAT = Utils.UI_FORMAT_DATE_TIME
  const [
    PODETAlaterThanCargoDeliveryDateMsgWarningDialogOpen,
    setPODETAlaterThanCargoDeliveryDateMsgWarningDialogOpen
  ] = useState(false)
  const [
    POLETDEarlierThanCargoReadyDateMsgWarningDialogOpen,
    setPOLETDEarlierThanCargoReadyDateMsgWarningDialogOpen
  ] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [warningDialogOpen, setWarningDialogOpen] = useState(false)
  //move this from BookingTypeComponent to share with Cargo component
  // const [hazardousField, , { setValue: setHazardousField }] = useField('hazardous')

  //move this from ShipmentComponent to handle onChange in ScheduleComponent
  const [placeOfReceiptCodeField, , { setValue: setPlaceOfReceiptCodeField }] =
    useField('placeOfReceiptCode')
  const [
    placeOfDeliveryCodeField,
    ,
    { setValue: setPlaceOfDeliveryCodeField }
  ] = useField('placeOfDeliveryCode')
  const [, , { setValue: setPlaceOfReceiptField }] = useField('placeOfReceipt')
  const [, , { setValue: setPlaceOfDeliveryField }] =
    useField('placeOfDelivery')

  const placeOfReceiptKeyRef = useRef(uuid())
  const placeOfDeliveryKeyRef = useRef(uuid())
  const polKeyRef = useRef(uuid())
  const podKeyRef = useRef(uuid())
  const moveTypeKeyRef = useRef(uuid())
  const documentKeyRef = useRef(uuid()) //to indicate the change in document
  const isEdit = useRef(false)
  const [dgCargo, setDGCargo] = useState(false)
  const { fetchRecords } = useServices()
  const { submitForm, setFieldValue } = useFormikContext()
  const [shouldRender, setShouldRender] = useState(false)
  const [forceExpand, setForceExpand] = useState(false)
  const amendBookingSteps = [
    fbTranslatedTextsObject.stepLabelAmendBooking,
    fbTranslatedTextsObject.stepLabelReviewBooking,
    fbTranslatedTextsObject.stepLabelSubmit
  ]

  function isEmpty(obj) {
    return Object.keys(obj).length === 0
  }

  const submitAmendments = async () => {
    if (!isEmpty(errors)) {
      setWarningDialogOpen(true)

      setForceExpand(true)
      //need to update move type key ref so that the shipment component will rethink to expand the section

      moveTypeKeyRef.current = uuid()
    }
    await setFieldValue('amendBooking', true) //use this to bypass the validation check for cargo first
    submitForm()
  }

  const openCancelDialog = () => {
    setConfirmDialogOpen(true)
  }

  const cancelBooking = () => {
    history.push({
      pathname: pathMap.MANAGE_BOOKING
    })
  }

  useEffect(() => {
    if (data === null) return
    if (data && data.prevPage === FrbUtils.Page.BookingList) {
      console.log('getbookingfromapi')
      const bookingRequest = {
        bookingId: data.booking ? data.booking.bookingId : '',
        dockey: data.booking ? data.booking.dockey : ''
      }
      Api.fetchBookingDetailsById(
        fetchRecords,
        bookingRequest,
        populateBookingData
      )
    } else if (data.booking !== null) {
      console.log('getbookingfromreview')
      setBooking(data.booking)
    }
  }, [])

  function populateBookingData(bookingData) {
    bookingData.bookStatusDesc = location.state.bookStatusDesc
    setBooking(bookingData)
  }

  useEffect(() => {
    if (!isEmpty(booking)) {
      placeOfReceiptKeyRef.current = uuid()
      placeOfDeliveryKeyRef.current = uuid()
      polKeyRef.current = uuid()
      podKeyRef.current = uuid()
      moveTypeKeyRef.current = uuid()
      documentKeyRef.current = uuid()
      // FetchPortCountryList()

      PopulateDataFromReview()
      setShouldRender(true)
    }
  }, [booking])

  function PopulateDataFromReview() {
    console.log('populateDataFromReview(): data : ', data)
    console.log('populateDataFromReview(): booking : ', booking)
    if (booking) {
      console.log('populate data representativeName ' + data.representativeName)
      const dataArr = Object.entries(booking)

      dataArr.forEach(([key, val]) => {
        if (FrbUtils.dateTimeFields.includes(key)) {
          val = Utils.formatString(val, Utils.UI_FORMAT_DATE_TIME)
        } else if (FrbUtils.dateFields.includes(key)) {
          val = Utils.formatString(val, Utils.UI_FORMAT_DATE)
        }

        //as we are using useArrayField for cargo/dg cargo, the setFieldValue cannot be null, hence need to convert null to [] for them
        if (FrbUtils.nonNullableArrayFields.includes(key)) {
          val = val === null ? [] : val
        } else if (FrbUtils.scheduleRelatedField.includes(key)) {
          val = val === null ? '' : val
        }

        setFieldValue(key, val)
      })

      isEdit.current = true
      setDGCargo(booking.hazardous)
      console.log(data.bookingStatus)
    }
  }

  if (!shouldRender) {
    return null
  }

  const validatePOLTEDAndCargoReadyDate = (e, field) => {
    setFieldValue(field, e, true)

    if (
      field === 'polEtd' &&
      cargoReadyDateField.value != null &&
      e != null &&
      moment(
        moment(cargoReadyDateField.value).format(SG_DATE_TIME_FORMAT)
      ).isAfter(moment(e).format(SG_DATE_TIME_FORMAT))
    ) {
      setPOLETDEarlierThanCargoReadyDateMsgWarningDialogOpen(true)
    }
  }

  const validatePODETAAndCargoDeliveryDate = (e, field) => {
    setFieldValue(field, e, true)

    if (
      field === 'podEta' &&
      cargoDeliveryDateField.value != null &&
      e != null &&
      moment(
        moment(cargoDeliveryDateField.value).format(SG_DATE_TIME_FORMAT)
      ).isBefore(moment(e).format(SG_DATE_TIME_FORMAT))
    ) {
      setPODETAlaterThanCargoDeliveryDateMsgWarningDialogOpen(true)
    }
  }

  return (
    <Box id="top-level-box">
      {/* {'Error :' + JSON.stringify(errors)}
         <br /> */}
      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>

      <Paper>
        <Box alignItems='center' pr={15} pl={15}>
          <GeneralStepperComponent steps={amendBookingSteps} activeStep={0} />
        </Box>

        <Box p={5} className='page-content'>
          <Box>
            <ViewBookingTypeComponent.FormBody bookingTypeData={booking} />
          </Box>
          <Box pt={5}>
            <AmendScheduleComponent.FormBody
              scheduleData={booking}
              showNotification={showNotification}
              polKeyRef={polKeyRef.current}
              podKeyRef={podKeyRef.current}
              placeOfReceiptKeyRef={placeOfReceiptKeyRef}
              placeOfDeliveryKeyRef={placeOfDeliveryKeyRef}
              setPlaceOfReceiptCodeField={setPlaceOfReceiptCodeField}
              setPlaceOfDeliveryCodeField={setPlaceOfDeliveryCodeField}
              setPlaceOfReceiptField={setPlaceOfReceiptField}
              setPlaceOfDeliveryField={setPlaceOfDeliveryField}
              PolEtdDateChange={validatePOLTEDAndCargoReadyDate}
              PodEtaDateChange={validatePODETAAndCargoDeliveryDate}
            />
          </Box>
          <Box pt={5}>
            <ShipmentComponent.FormBody
              placeOfReceiptKeyRef={placeOfReceiptKeyRef.current}
              placeOfDeliveryKeyRef={placeOfDeliveryKeyRef.current}
              moveTypeKeyRef={moveTypeKeyRef.current}
              // countryCodeByPortCodeRef={countryCodeByPortCodeRef}
              placeOfReceiptCodeField={placeOfReceiptCodeField}
              setPlaceOfReceiptField={setPlaceOfReceiptField}
              setPlaceOfDeliveryField={setPlaceOfDeliveryField}
              placeOfDeliveryCodeField={placeOfDeliveryCodeField}
              isEdit={isEdit.current}
              isCarrierAmend
              forceExpand={forceExpand}
              setForceExpand={setForceExpand}
            />
          </Box>
          <Box pt={5}>
            <ContainerComponent.FormBody
              isEdit={isEdit.current}
              isCarrierAmend
            />
          </Box>

          {dgCargo ? (
            <Box pt={5}>
              <ViewDgCargoComponent.FormBody
                cargo={booking.bookingDGCargoes}
                showDetails
              />
            </Box>
          ) : (
            <Box pt={5}>
              <ViewCargoComponent.FormBody
                cargo={booking.bookingCargoes}
                showDetails
              />
            </Box>
          )}
          <Box pt={5}>
            <DocumentComponent.FormBody
              isEdit={isEdit.current}
              documentKeyRef={documentKeyRef.current}
            />
          </Box>
          <Box pt={5}>
            <ContactDetailsComponent.FormBody
              isEdit={isEdit.current}
              isCarrierAmend
            />
          </Box>
          <Box pt={5}>
            <ViewPaymentComponent.FormBody paymentData={booking} showDetails />
          </Box>

          <Box pt={5}>
            <ApprovalComponent.FormBody isEdit={isEdit.current} />
          </Box>
          <QuickScroll />
        </Box>
      </Paper>
      <Paper className='sticky-footer'>
        <Box alignItems='center'>
          <ManageBookingAmendButtonComponent
            onDiscard={openCancelDialog}
            onConfirmReview={submitAmendments}
          />
        </Box>
      </Paper>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={cancelBooking}
        content={fbTranslatedTextsObject.dialogDiscardBookingContent}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={fbTranslatedTextsObject.dialogDiscardBookingTitle}
      />

      <WarningDialog
        isDialogOpen={warningDialogOpen}
        closeDialog={() => setWarningDialogOpen(false)}
        okDialog={() => setWarningDialogOpen(false)}
        content={fbTranslatedTextsObject.formErrorWarning}
        okMsg={uiTranslatedTextsObject.ok}
      />

      <WarningDialog
        isDialogOpen={PODETAlaterThanCargoDeliveryDateMsgWarningDialogOpen}
        closeDialog={() =>
          setPODETAlaterThanCargoDeliveryDateMsgWarningDialogOpen(false)
        }
        okDialog={() =>
          setPODETAlaterThanCargoDeliveryDateMsgWarningDialogOpen(false)
        }
        content={
          validationMessageTranslation.PODETAlaterThanCargoDeliveryDateMsg
        }
        okMsg={uiTranslatedTextsObject.ok}
      />

      <WarningDialog
        isDialogOpen={POLETDEarlierThanCargoReadyDateMsgWarningDialogOpen}
        closeDialog={() =>
          setPOLETDEarlierThanCargoReadyDateMsgWarningDialogOpen(false)
        }
        okDialog={() =>
          setPOLETDEarlierThanCargoReadyDateMsgWarningDialogOpen(false)
        }
        content={
          validationMessageTranslation.POLETDEarlierThanCargoReadyDateMsg
        }
        okMsg={uiTranslatedTextsObject.ok}
      />
    </Box>
  )
}

const ManageBookingAmendFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
  // toClientDataFormat: toClientDataFormat,
  // toServerDataFormat: toServerDataFormat
})

export default ManageBookingAmendFormProperties
