import * as CalistaConstant from 'src/constants/CalistaConstant'

import {
    Box,
    Grid,
    Paper
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'

import ApproveBookingDialog from './ApproveBookingDialog'
import BookingInfoComponent from '../../components/BookingInfoComponent'
import CompanyUserGroupCrudTable from 'src/views/account-setup/companyforusergroup/CompanyUserGroupCrudTable'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import HeaderBarTop from '../../components/HeaderBarTop'
import ManageBookingButtonComponent from '../../components/ManageBookingButtonComponent'
import ManageBookingReviewHeader from '../../components/ManageBookingReviewHeader'
import Utils from '../../shared/Utils'
import ViewBookingTypeComponent from '../../components/ViewBookingTypeComponent'
import ViewCargoComponent from '../../components/ViewCargoComponent'
import ViewContactComponent from '../../components/ViewContactComponent'
import ViewContainerComponent from '../../components/ViewContainerComponent'
import ViewReeferComponent from '../../components/ViewReeferComponent'
import ViewDgCargoComponent from '../../components/ViewDgCargoComponent'
import ViewLastModifiedComponent from '../../components/ViewLastModifiedComponent'
import ViewPaymentComponent from '../../components/ViewPaymentComponent'
import ViewRemarksComponent from '../../components/ViewRemarksComponent'
import ViewScheduleComponent from '../../components/ViewScheduleComponent'
import ViewShipmentComponent from '../../components/ViewShipmentComponent'
import pathMap from 'src/paths/PathMap_FreightBooking'
import { useHistory } from 'react-router-dom'
import ViewDocumentComponent from '../../components/ViewDocumentComponent'
import AuditTrailTableComponent from 'src/components/auditTrail/AuditTrailTableComponent'
import AuditTrailSummary from '../../components/AuditTrailSummary'
import CommonUtils from 'src/views/common/utils/Utils'
import Api from '../../shared/api'
import AuditTrialRemarks from 'src/components/auditTrail/AuditTrailRemarks'
import ViewAuditDetailDialog from '../../components/ViewAuditDetailDialog'
import CarrierBookingActionMenu from '../../components/CarrierBookingActionMenu'
import QuickScroll from 'src/components/iconbutton/QuickScroll'
const {
    form: {
        adapter: {
            useFormAdapter: { useField, useFormikContext }
        }
    },
    CngGridItem,
    table: { useDefaultNotification }
} = components


function FormFields(props) {
    const { booking, ports, userCriteria, setUserCriteria } = props
    const history = useHistory()
    const { createRecord, fetchRecords } = useServices()
    const translatedTextsObject = FreightBookingTranslationText()
    const { setFieldValue } = useFormikContext()

    const [newDate, setNewDate] = useState('')
    const [prevDate, setPrevDate] = useState('')
    const [auditCompare, setAuditCompare] = useState({})
    const [isDialogOpen, setDialogOpen] = useState(false)
    const [auditList, setAuditList] = useState([])

    const containers = booking.bookingContainers || []
    const cargos = booking.bookingCargoes || []
    const dgCargos = booking.bookingDGCargoes || []
    const documents = booking.bookingDocuments || []

    // console.log(props)
    const showNotification = props.showNotification
    console.log('Manage Booking Review : ' + JSON.stringify(booking))

    const [bookingStatus, setBookingStatus] = useState()
    // const [approve, setApprove] = useState(false)
    const [approve, ,] = useField('approve')
    const [isApproveBookingDialog, setIsApproveBookingDialog] = useState(false)

    const [showButton, setShowButton] = useState({})
    const [shouldRender, setShouldRender] = useState(false)
    const [auditBooking, setAuditBooking] = useState({})


    console.log(showNotification)
    const {
        success: showSuccessNotification,
        error: showErrorNotification
    } = useDefaultNotification(showNotification)


    useEffect(() => {
        console.log('showButton setBookingStatus >' + booking.bookingStatus)
        setBookingStatus(booking.bookingStatus)
        setAuditList(props.auditList)

    }, [props])

    useEffect(() => {
        console.log("setShowButton")
        setShowButton(Utils.getShowButtonForCarrier(bookingStatus))
        console.log('showButton >' + JSON.stringify(showButton))
        setShouldRender(true)

        if (bookingStatus == CalistaConstant.BOOKING_STATUS_AMENDED) {
            getAuditCompare(auditList)
        }
    }, [bookingStatus, auditList])


    function getAuditCompare(auditList) {
        if (auditList && auditList.length > 1) {
            let newVersion = auditList[0].frozenVersion
            let oldVersion = auditList[1].frozenVersion

            const versions = {
                oldVersion: oldVersion,
                newVersion: newVersion
            }
            Api.fetchAuditTrailCompare(fetchRecords, versions, onAuditTrailLoadSuccess)
        }

    }
    function onAuditTrailLoadSuccess(data) {
        setAuditBooking(data)
        console.log('Audit trail compare: ', data)
    }

    if (!shouldRender) {
        return null;
    }

    const openApproveBookingDialog = async () => {
        console.log(bookingStatus)
        // if (bookingStatus == CalistaConstant.BOOKING_STATUS_AMENDED) {
        //     //directly approve the booking
        //     carrierApproveAmendedBooking()
        // } else {
        //approve dialog open only for pending approval booking
        // await setApprove(true)
        await setFieldValue('approve', true)
        setIsApproveBookingDialog(true)
        // }
    }

    function closeDialog() {
        setIsApproveBookingDialog(false)
    }

    const openDeclinBookingDialog = async () => {
        // if (bookingStatus == CalistaConstant.BOOKING_STATUS_AMENDED) {
        //     //directly reject the booking
        //     carrierRejectAmendedBooking()
        // } else {
        // await setApprove(false)
        await setFieldValue('approve', false)
        setIsApproveBookingDialog(true)
        //  }
    }

    const carrierApproveAmendedBooking = () => {
        createRecord.execute(
            FreightBookingApiUrls.APPROVE_AMEND_BOOKING,
            booking,
            onSuccess,
            onError
        )
    }


    const carrierRejectAmendedBooking = () => {
        createRecord.execute(
            FreightBookingApiUrls.REJECT_AMEND_BOOKING,
            booking,
            onSuccess,
            onError
        )
    }


    function onSuccess(response) {
        console.log('connection-request onSuccess', response)
        if (response.errorMessages != null) {
            showErrorNotification(response.errorMessages)
        } else {

            // data.bookingId = response.bookingId
            // data.freightBookingId = response.freightBookingId
            booking.bookingStatus = response.bookingStatus
            setBookingStatus(response.bookingStatus)
            if (response.bookingStatus == CalistaConstant.BOOKING_STATUS_APPROVED) {
                showSuccessNotification(`${translatedTextsObject.approveSuccess}`)
            } else {
                showSuccessNotification(`${translatedTextsObject.rejectSuccess}`)
            }
        }
        //  setLoading(false)

        history.push({
            pathname: pathMap.MANAGE_BOOKING_REVIEW,
            state: booking
        })
    }

    function onError(error) {
        console.log('connection-request error', error.message)
        // setLoading(false)
        showErrorNotification(CommonUtils.getErrorMessage(error))
    }


    return (

        <Box id="top-level-box">
            {/* <ManageBookingReviewHeader booking={booking} bookingStatus={bookingStatus}></ManageBookingReviewHeader> */}
            <HeaderBarTop booking={booking} bookingStatus={bookingStatus} isCarrier></HeaderBarTop>
            <Paper>
                <Box p={5} borderTop={1} borderBottom={1} className="border-light-blue" >
                    {
                        bookingStatus == CalistaConstant.BOOKING_STATUS_AMENDED && (
                            <>
                                <Box pb={5}>
                                    <AuditTrialRemarks />
                                </Box>
                                <Box pb={5}>
                                    <AuditTrailSummary booking={auditBooking} />
                                </Box>
                            </>
                        )
                    }
                    <BookingInfoComponent.FormBody
                        bookingStatus={bookingStatus}
                        booking={booking}
                    />


                    <Box pt={5}>
                        <Grid container>
                            <CngGridItem xs={4} sm={4}>
                                <ViewBookingTypeComponent.FormBody bookingTypeData={booking} />
                            </CngGridItem>

                            <CngGridItem item xs={2} sm={2} />

                            {
                                booking.shipperRemarks &&
                                <ViewRemarksComponent.FormBody
                                    title={translatedTextsObject.shipperComments}
                                    remarks={booking.shipperRemarks}
                                    style={CommonUtils.auditHighLight(auditBooking, ['shpRemarks'])}
                                />
                            }
                        </Grid>
                    </Box>

                    <Box pt={5}>
                        <ViewScheduleComponent.FormBody scheduleData={booking} showDetails isCarrier auditBooking={auditBooking} />
                    </Box>

                    <Box pt={5}>
                        <ViewShipmentComponent.FormBody shipmentData={booking} showDetails isCarrier auditBooking={auditBooking} />
                    </Box>

                    <Box pt={5}>
                        <ViewContainerComponent.FormBody containers={containers} auditBooking={auditBooking} />
                    </Box>

                    {booking.reeferAvailble &&
                        <Box pt={5}>
                            <ViewReeferComponent.FormBody data={booking} />
                        </Box>
                    }

                    {
                        cargos.length > 0 && (
                            <Box pt={5}>
                                <ViewCargoComponent.FormBody cargo={cargos} showDetails />
                            </Box>
                        )
                    }
                    {
                        dgCargos.length > 0 && (
                            <Box pt={5}>
                                <ViewDgCargoComponent.FormBody cargo={dgCargos} showDetails />
                            </Box>
                        )
                    }

                    {
                        documents.length > 0 && (
                            <Box pt={5}>
                                <ViewDocumentComponent.FormBody document={documents} auditBooking={auditBooking} allowDownload />
                            </Box>
                        )}

                    <Box pt={5}>
                        <ViewContactComponent.FormBody contactData={booking} auditBooking={auditBooking} />
                    </Box>

                    <Box pt={5}>
                        <ViewPaymentComponent.FormBody paymentData={booking} />
                    </Box>

                    <Box pt={5}>
                        <AuditTrailTableComponent.FormBody booking={booking} ports={ports} auditList={auditList} userCriteria={userCriteria}
                            setDialogOpen={(data) => setDialogOpen(data)}
                            setAuditCompare={(data) => setAuditCompare(data)}
                            setNewDate={(data) => setNewDate(data)}
                            setPrevDate={(data) => setPrevDate(data)} />
                        <ViewAuditDetailDialog
                            isDialogOpen={isDialogOpen}
                            auditCompare={auditCompare}
                            newDate={newDate}
                            prevDate={prevDate}
                            currentBooking={booking}
                            currentBookStatus={booking.bookingStatus}
                            closeDialog={() => setDialogOpen(false)}
                            ports={ports}
                        />
                    </Box>
                    <QuickScroll />
                </Box>


            </Paper>
            <Paper className='sticky-footer'>
                {/* <Paper> */}
                <Box alignItems='center'>
                    <CarrierBookingActionMenu booking={booking}
                        page={Utils.Page.BookingDetail}
                        onClickApproveBooking={openApproveBookingDialog}
                        onClickDeclineBooking={openDeclinBookingDialog}
                        bookingStatus={bookingStatus}
                        {...props} />

                    {/* <ManageBookingButtonComponent
                        booking={booking}
                        onClickApproveBooking={openApproveBookingDialog}
                        onClickDeclineBooking={openDeclinBookingDialog}
                        showButton={showButton}
                        {...props} /> */}
                </Box>
            </Paper>

            <ApproveBookingDialog
                isDialogOpen={isApproveBookingDialog}
                closeDialog={closeDialog}
                showNotification={showNotification}
                booking={booking}
                approve={approve.value}
                setBookingStatus={setBookingStatus}
                setAuditList={setAuditList}
                setUserCriteria={setUserCriteria}
            />
            {/* {alert(bookingStatus)} */}
        </Box>
    )
}

const ManageBookingReview = Object.freeze({
    // formikProps: FORMIK_PROPS,
    FormFields: FormFields
    // toClientDataFormat: toClientDataFormat,
    // toServerDataFormat: toServerDataFormat
})


export default ManageBookingReview