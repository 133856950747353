import React, { useRef, useState, useEffect } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useParams, useLocation } from 'react-router-dom'

import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import FreightBookingTranslationText from '../../shared/FreightBookingTranslationText'
import ManageBookingAmendFormProperties from './ManageBookingAmendFormProperties'
import pathMap from 'src/paths/PathMap_FreightBooking'
import Api from '../../shared/api'
import Utils from '../../shared/Utils'
import useManageBookingAmendMakeValidationSchema from './useManageBookingAmendMakeValidationSchema'

const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function ManageBookingAmendPage({ showNotification }) {
  const { id } = useParams()
  const location = useLocation()
  const history = useHistory()
  const { createRecord } = useServices()
  const [loading, setLoading] = useState(false)
  const translatedTextsObject = FreightBookingTranslationText()

  const bookingId = useRef()
  const freightBookingId = useRef()
  const [booking, setBooking] = useState([])
  const { fetchRecords } = useServices()
  const hisotryData = location.state
  const validationSchema = useManageBookingAmendMakeValidationSchema()

  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)


  function onSubmit(data) {

    console.log(data)
    history.push({
      pathname: pathMap.MANAGE_BOOKING_AMEND_REVIEW,
      state: data
    })


  }

  const bookingRequest = {
    bookingId: location.state.bookingId,
    dockey: location.state.dockey
  }


  // useEffect(() => {
  //   console.log(hisotryData)
  //   if (hisotryData.page == Utils.Page.BookingList) {
  //     console.log('start get booking')
  //     Api.fetchBookingDetailsById(fetchRecords, bookingRequest, populateBookingData)
  //   } else {
  //     console.log("get data from history")
  //     setBooking(hisotryData)
  //   }
  // }, [])

  // function populateBookingData(data) {
  //   // data.bookStatusDesc = location.state.bookStatusDesc

  //   setBooking(data)
  // }

  return (

    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <ManageBookingAmendFormProperties.FormFields
          disabled={false}
          showNotification={showNotification}
          loading={loading}
          booking={booking}
        />
      }
      formikProps={{
        ...ManageBookingAmendFormProperties.formikProps,
        validationSchema,
        onSubmit: onSubmit
      }}
    // toClientDataFormat={PollingFormProperties.toClientDataFormat}
    // toServerDataFormat={PollingFormProperties.toServerDataFormat}
    />

  )
}

export default ManageBookingAmendPage
