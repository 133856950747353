import { DateTimeFormatter, Yup, components, useServices, constants } from 'cng-web-lib'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import Api from '../../shared/api'
import ApiParser from '../../shared/apiParser'
import HeaderBarTop from '../../components/HeaderBarTop'
import ManageBookingReview from './ManageBookingReview'
import ViewBookingTypeComponent from '../../components/ViewBookingTypeComponent'
import ViewCargoComponent from '../../components/ViewCargoComponent'
import ViewContainerComponent from '../../components/ViewContainerComponent'
import ViewScheduleComponent from '../../components/ViewScheduleComponent'
import makeValidationSchema from '../../shared/MakeValidationSchema'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
import SharedServiceApi from 'src/views/common/api/SharedServiceApi'
import CarrierApi from 'src/views/common/api/CarrierApi'
import * as CalistaConstant from 'src/constants/CalistaConstant'
const {
  form: { CngViewForm }
} = components

const {
  CodeMaintenanceType
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  ...ViewBookingTypeComponent.initialValues,
  ...ViewScheduleComponent.initialValues,
})
const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function FormFields(props) {
  const location = useLocation()
  const { fetchRecords } = useServices()
  const bookStatus = location.state.bookStatus

  console.log('BodySection bookStatus: ' + bookStatus)
  const [booking, setBooking] = useState({})
  const [auditList,setAuditList] = useState([])
  const [ports, setPorts] = useState([])
  const [userCriteria, setUserCriteria] = useState([])

  const bookingRequest = {
    bookingId: location.state.bookingId,
    dockey: location.state.dockey
  }
  useEffect(() => {
    console.log('start get booking')
    Api.fetchBookingDetailsById(fetchRecords, bookingRequest, populateBookingData)

    CodeMaintainanceApi.fetchCodeMaintainance(fetchRecords, CodeMaintenanceType.PORT,
      [], false, onLoadPortSuccess)

  }, [])

  
  function onLoadPortSuccess(data) {
    setPorts(data)
  }

  function populateBookingData(data) {
    data.bookStatusDesc = location.state.bookStatusDesc
    setBooking(data)
    if (data.freightBookingId) {
      Api.fetchAuditTrailListing(fetchRecords, data.freightBookingId, onAuditTrailListingLoadSuccess)
    }
  }
  function onAuditTrailListingLoadSuccess(data) {
    data.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
    setAuditList(data)
    let userList = []
    //get user list
    data.forEach((val)=>{
      userList.push(val.userId)
    })
    setUserCriteria(userList)
  }
  

  return (
    <div>
      {console.log('start render component')}
      {/* <HeaderBarTop booking={booking}></HeaderBarTop> */}

      <ManageBookingReview.FormFields booking={booking} ports={ports} auditList={auditList} userCriteria={userCriteria} setUserCriteria={setUserCriteria} {...props} />

      {/* {
        bookStatus === 5107 && (
          <ManageBookingReview.FormFields booking={booking} {...props} />
        )
      }
      {
        bookStatus === 5101 && (
          <ManageBookingReview.FormFields booking={booking} {...props} />
        )
      }
      {
        bookStatus === 5102 && (
          <ManageBookingReview.FormFields booking={booking} {...props} />
        )
      }
      {
        bookStatus === 5104 && (
          <ManageBookingReview.FormFields booking={booking} {...props} />
        )
      }
      {
        bookStatus === 5103 && (
          //declined is the same as cancel
          <ManageBookingReview.FormFields booking={booking} {...props} />
        )
      } */}
    </div>
  )
}

const BodySection = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})


export default BodySection
