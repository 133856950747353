import React, { useState } from 'react'

import ApproveBookingDialogContext from './ApproveBookingDialogContext.js'
import ApproveBookingForm from './ApproveBookingForm.js'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import Paper from '@material-ui/core/Paper'
import { components } from 'cng-web-lib'

const { CngDialog } = components

function ApproveBookingDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  booking,
  approve,
  setBookingStatus,
  setAuditList,
  setUserCriteria
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = FreightBookingTranslationText()

  return (
    <ApproveBookingDialogContext.Provider
      value={{
        closeDialog,
        showNotification,
        form: {
          isSubmitting,
          setSubmitting
        }
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <ApproveBookingForm booking={booking} approve={approve} setBookingStatus={setBookingStatus} setAuditList={setAuditList} setUserCriteria={setUserCriteria} />
          </Paper>
        }
        dialogTitle={
          <>
            {approve ? (<b>{translatedTextsObject.approval}</b>) : (<b>{translatedTextsObject.declineBooking}</b>)}
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='sm'
      />
    </ApproveBookingDialogContext.Provider>
  )
}

export default ApproveBookingDialog
