import { useHistory, useParams } from 'react-router-dom'

import ManageBookingAmendReviewFormProperties from './ManageBookingAmendReviewFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  form: { CngViewForm }
} = components

function ManageBookingAmendReviewPage({ showNotification }) {
  const { id } = useParams()
  const history = useHistory()

  function onSubmit(data) {
    console.log('submit', data)
    // if(data['saveDraft']){
    //   saveDraft(data)
    // }else{
    //   submitBooking()
    //   // history.push({
    //   //   pathname: '/cal-freightbooking/create-new-booking/review',
    //   //   state: data
    //   // })
    // }
  }

  return (

    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <ManageBookingAmendReviewFormProperties.FormFields
          disabled={false}
          showNotification={showNotification}
        />
      }
      formikProps={{
        ...ManageBookingAmendReviewFormProperties.formikProps,
        onSubmit: onSubmit
      }}
    // toClientDataFormat={PollingFormProperties.toClientDataFormat}
    // toServerDataFormat={PollingFormProperties.toServerDataFormat}
    />

  )
}

export default ManageBookingAmendReviewPage
