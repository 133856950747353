
import ContactValidationSchema from 'src/views/freightbooking/shared/validation/ContactValidationSchema'
import BookingTypeValidationSchema from 'src/views/freightbooking/shared/validation/BookingTypeValidationSchema'
import ScheduleValidationSchema from 'src/views/freightbooking/shared/validation/ScheduleValidationSchema'
import ShipmentValidationSchema from 'src/views/freightbooking/shared/validation/ShipmentValidationSchema'
import ContainerValidationSchema from 'src/views/freightbooking/shared/validation/ContainerValidationSchema'
import ApprovalValidationSchema from 'src/views/freightbooking/shared/validation/ApprovalValidationSchema'
import DocumentValidationSchema from 'src/views/freightbooking/shared/validation/DocumentValidationSchema'



function useManageBookingAmendMakeValidationSchema(translate) {



  return ContactValidationSchema()
    .concat(ShipmentValidationSchema())
    .concat(ScheduleValidationSchema())
    .concat(ContainerValidationSchema())
    .concat(ApprovalValidationSchema())
    .concat(DocumentValidationSchema())


}

export default useManageBookingAmendMakeValidationSchema
