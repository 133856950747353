import { BasePage as CngBasePage } from 'cng-web-lib'
import ManageBookingAmendPage from './amend/ManageBookingAmendPage'
import ManageBookingAmendReviewPage from './amend/ManageBookingAmendReviewPage'
import ManageBookingPage from './ManageBooking.js'
import ManageBookingReviewPage from './review/ManageBookingReviewPage'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_FreightBooking'
import TranslationText from '../shared/FreightBookingTranslationText'

function WrappedManageBookingPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = TranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ManageBookingPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedManageBookingReviewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = TranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ManageBookingReviewPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedManageBookingAmendPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = TranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ManageBookingAmendPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedManageBookingAmendReviewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = TranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ManageBookingAmendReviewPage
          showNotification={showSnackbar}
          {...props}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedManageBookingAmendSubmittedPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = TranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ManageBookingAmendReviewPage
          showNotification={showSnackbar}
          {...props}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedManageBookingPage as ManageBookingPage,
  WrappedManageBookingReviewPage as ManageBookingReviewPage,
  WrappedManageBookingAmendPage as ManageBookingAmendPage,
  WrappedManageBookingAmendReviewPage as ManageBookingAmendReviewPage,
  WrappedManageBookingAmendSubmittedPage as ManageBookingAmendSubmittedPage
}
