import * as calistaConstant from 'src/constants/CalistaConstant'

import { Box, Paper } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CngBackdrop from '../../../vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import FreightBookingTranslationText from '../../shared/FreightBookingTranslationText'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import ManageBookingAmendReviewButtonComponent from './ManageBookingAmendReviewButtonComponent'
import ManageBookingAmendedButtonComponent from '../../components/ManageBookingAmendedButtonComponent'
import ViewApprovalComponent from '../../components/ViewApprovalComponent'
import ViewBookingTypeComponent from '../../components/ViewBookingTypeComponent'
import ViewCargoComponent from '../../components/ViewCargoComponent'
import ViewContactComponent from '../../components/ViewContactComponent'
import ViewContainerComponent from '../../components/ViewContainerComponent'
import ViewPaymentComponent from '../../components/ViewPaymentComponent'
import ViewScheduleComponent from '../../components/ViewScheduleComponent'
import ViewShipmentComponent from '../../components/ViewShipmentComponent'
import ViewDocumentComponent from '../../components/ViewDocumentComponent'
import makeValidationSchema from 'src/views/freightbooking/shared/MakeValidationSchema'
import moment from 'moment'
import pathMap from 'src/paths/PathMap_FreightBooking'
import Utils from '../../shared/Utils'
import ViewDgCargoComponent from '../../components/ViewDgCargoComponent'
import CommonUtils from 'src/views/common/utils/Utils'
import QuickScroll from 'src/components/iconbutton/QuickScroll'

const DEFAULT_INITIAL_VALUES = Object.freeze({
    bookingId: '',
    setFreightBookingId: '',
    bookingStatus: '',
    ...ViewBookingTypeComponent.initialValues,
    ...ViewScheduleComponent.initialValues,
})

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

const {
    table: { useDefaultNotification },
    CngGridItem
} = components

function FormFields({ disabled, showNotification, shouldHideMap }) {

    const translatedTextsObject = FreightBookingTranslationText()
    const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
    // const calistaConstant = CalistaConstant()
    //get data from useHistory

    const { createRecord } = useServices()
    const history = useHistory()
    const location = useLocation();
    const data = location.state
    const containers = location.state.bookingContainers;
    const cargo = location.state.bookingCargoes == null ? [] : location.state.bookingCargoes;
    const dgCargo = location.state.bookingDGCargoes == null ? [] : location.state.bookingDGCargoes;
    const documents = getDocumentList()
    const hazardous = location.state.hazardous
    const [loading, setLoading] = useState(false)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [bookingStep, setBookingStep] = useState(1)
    const bookingId = useRef()
    const freightBookingId = useRef()
    const [bookingStatus, setBookingStatus] = useState()
    const [isSubmitted, setIsSubmitted] = useState(false)

    const {
        success: showSuccessNotification,
        error: showErrorNotification
    } = useDefaultNotification(showNotification)

    const onEdit = () => {

        history.push({
            pathname: pathMap.MANAGE_BOOKING_AMEND,
            state: { booking: data }
        })
    }

    function getDocumentList() {

        if (data.bookingSubmitted) {
            //step 3 , booking document only
            return location.state.bookingDocuments == null ? [] : location.state.bookingDocuments
        } else {
            //step 2 , combine document and pending document list
            let pendingList = location.state.pendingBookingDocuments == null ? [] : processPendingDocList(location.state.pendingBookingDocuments)
            return location.state.bookingDocuments == null
                ?
                (pendingList)
                :
                location.state.bookingDocuments.concat(pendingList)
        }


    }

    function processPendingDocList(pendingDocList) {
        console.log('testdoc, pendingDocList start', pendingDocList)
        pendingDocList.forEach((doc) => {
            doc.docType = doc.fields[0].value
            doc.description = doc.fields[1].value
        })

        console.log('testdoc, pendingDocList end', pendingDocList)
        return pendingDocList
    }

    function onSubmitBooking() {
        setLoading(true)

        Utils.convertDate(data)
        // const dataArr = Object.entries(data);
        // dataArr.map(([key, val]) => {

        //     if (Utils.dateTimeFields.includes(key)) {
        //         if (val != null && val != "") {
        //             var test = new moment(val, CommonUtils.UI_FORMAT_DATE_TIME).utc();
        //             data[key] = test.format(CommonUtils.FORMAT_DATE_SERVER_DATE)
        //         }
        //     }

        //     else if (Utils.dateFields.includes(key)) {
        //         if (val != null && val != "") {
        //             var test2 = new moment(val, CommonUtils.UI_FORMAT_DATE).utc();
        //             data[key] = test2.format(CommonUtils.FORMAT_DATE_SERVER_DATE)
        //         }
        //     }

        // });

        // console.log(data)

        if (data.pendingBookingDocuments && !Utils.isEmpty(data.pendingBookingDocuments)) {
            uploadDocumentAndApproveBooking()
        } else {
            carrierApproveBooking(data)
        }


    }

    function uploadDocumentAndApproveBooking() {

        setLoading(true)

        var formData = new FormData();
        console.log(data.pendingBookingDocuments)

        data.pendingBookingDocuments.map((file, i) => {
            var fileBlob = file.file
            console.log(fileBlob)
            formData.append("fileContents", fileBlob); // name of the file upload box 

        })


        function onSuccess(response) {
            console.log('connection-request onSuccess', response)
            // setLoading(false)
            if (response.errorMessages != null) {

                showErrorNotification(response.errorMessages)

            } else {
                //showSuccessNotification(`${translatedTextsObject.saveDraftSuccess} ${response.freightBookingId}`)
                console.log(response)
                let bookingDocs = data.bookingDocuments || [] //to store the final booking documents
                if (response && response.length > 0) {
                    response.map((file, i) => {
                        let bookingDoc = {
                            docType: data.pendingBookingDocuments[i].fields[0].value,
                            filename: file.fileName,
                            description: data.pendingBookingDocuments[i].fields[1].value,
                            docId: file.id,
                            filesize: file.fileSize
                        }

                        bookingDocs.push(bookingDoc)
                    })
                }

                data.bookingDocuments = bookingDocs

                carrierApproveBooking(data)
            }
        }

        function onError(error) {
            console.log('connection-request error', error.message)
            setLoading(false)
            showErrorNotification(CommonUtils.getErrorMessage(error))
        }

        createRecord.execute(
            FreightBookingApiUrls.DOCUMENT_UPLOAD,
            formData,
            onSuccess,
            onError
        )
    }


    function carrierApproveBooking(newBookingData) {

        console.log(data.bookingStatus)
        console.log("carrierApproveBooking")
        function onSuccess(response) {
            console.log('connection-request onSuccess', response)
            if (response.errorMessages != null) {
                showErrorNotification(response.errorMessages)
            } else {

                showSuccessNotification(`${translatedTextsObject.approveSuccess}`)

                // data.bookingId = response.bookingId
                // data.freightBookingId = response.freightBookingId
                newBookingData.bookingStatus = response.bookingStatus
                newBookingData.bookingSubmitted = true
            }
            setLoading(false)

            history.push({
                pathname: pathMap.MANAGE_BOOKING_AMEND_SUBMITTED,
                state: newBookingData
            })
        }

        function onError(error) {
            console.log('connection-request error', error.message)
            setLoading(false)
            showErrorNotification(CommonUtils.getErrorMessage(error))
        }


        createRecord.execute(
            getAmendmentURL(data.bookingStatus),
            newBookingData,
            onSuccess,
            onError
        )

    }

    function getAmendmentURL(bookStatus) {

        console.log("getAmendmentURL " + bookStatus)
        switch (bookStatus.toString()) {
            case calistaConstant.BOOKING_STATUS_PENDING_APPROVAL:
                return FreightBookingApiUrls.APPROVE_BOOKING
            case calistaConstant.BOOKING_STATUS_AMENDED:
                return FreightBookingApiUrls.APPROVE_AMEND_BOOKING
            case calistaConstant.BOOKING_STATUS_APPROVED:
                return FreightBookingApiUrls.CARRIER_AMEND_BOOKING
            default:
                return ''
        }
    }



    const onDiscard = () => {
        setConfirmDialogOpen(true)
    }

    const navigateManageBooking = () => {
        history.push({
            pathname: pathMap.MANAGE_BOOKING
        })
    }


    useEffect(() => {
        console.log('populate review representativeName ' + data.representativeName)
        setBookingStatus(data.bookingStatus)

        // if (data.bookingStatus != 'undefined') {
        //     if (data.bookingStatus == calistaConstant.BOOKING_STATUS_APPROVED) {
        //         setIsSubmitted(true)
        //         setBookingStep(2)
        //     }
        // }

        console.log(data)

        if (data.bookingSubmitted) {
            setIsSubmitted(true)
            setBookingStep(2)
        }
    }, [])

    const amendBookingSteps = [
        translatedTextsObject.stepLabelAmendBooking,
        translatedTextsObject.stepLabelReviewBooking,
        translatedTextsObject.stepLabelSubmit
    ]

    return (
        <Box id="top-level-box">
            <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                <CngBackdrop loading={loading} />
            </CngGridItem>
            <Paper>
                <Box alignItems='center' pr={15} pl={15}>
                    <GeneralStepperComponent steps={amendBookingSteps} activeStep={bookingStep} />
                </Box>

                {/* {JSON.stringify(location.state)} */}

                <Box p={5} borderTop={1} borderBottom={1} className="border-light-blue" >
                    <Box fontWeight={600} fontSize="h3.fontSize" >
                        <Box display={isSubmitted ? 'none' : ''}>
                            {translatedTextsObject.reviewBooking}
                        </Box>
                        <Box display={isSubmitted ? '' : 'none'}>
                            <Box display="flex" flexDirection="row">
                                {translatedTextsObject.bookingApproved}
                                <Box pl={1.5}>
                                    <CheckCircleOutlineOutlinedIcon className='light-green' />
                                </Box>

                            </Box>
                            <Box className='info-label' pt={2} mb={-2}>
                                {translatedTextsObject.bookingApprovedOn}
                                {moment().format(' MMMM Do yyyy')}
                            </Box>
                        </Box>
                    </Box>

                    <Box pt={5}>
                        <ViewBookingTypeComponent.FormBody bookingTypeData={data} />
                    </Box>

                    <Box pt={5}>
                        <ViewScheduleComponent.FormBody scheduleData={data} showDetails isCarrier/>
                    </Box>

                    <Box pt={5}>
                        <ViewShipmentComponent.FormBody shipmentData={data} showDetails />
                    </Box>

                    <Box pt={5}>
                        <ViewContainerComponent.FormBody containers={containers} showDetails />
                    </Box>

                    {hazardous ?
                        (<Box pt={5}>
                            <ViewDgCargoComponent.FormBody cargo={dgCargo} showDetails />
                        </Box>)
                        :
                        (<Box pt={5}>
                            <ViewCargoComponent.FormBody cargo={cargo} showDetails />
                        </Box>
                        )}

                    {
                        documents.length > 0 && (
                            <Box pt={5}>
                                <ViewDocumentComponent.FormBody document={documents} />
                            </Box>
                        )}
                    <Box pt={5}>
                        <ViewContactComponent.FormBody contactData={data} />
                    </Box>

                    <Box pt={5}>
                        <ViewPaymentComponent.FormBody paymentData={data} />
                    </Box>

                    <Box pt={5}>
                        <ViewApprovalComponent.FormBody booking={data} />
                    </Box>
                    <QuickScroll />
                </Box>
            </Paper>
            <Paper className='sticky-footer'>
                {/* <Paper> */}
                <Box alignItems='center'>
                    {isSubmitted ?
                        <ManageBookingAmendedButtonComponent
                            onManageBooking={navigateManageBooking}
                        />
                        : <ManageBookingAmendReviewButtonComponent

                            onEdit={onEdit}
                            onDiscard={onDiscard}
                            onSubmitBooking={onSubmitBooking}
                        />}

                </Box>
            </Paper>
            <ConfirmDialog
                isConfirmDialogOpen={confirmDialogOpen}
                closeDialog={() => setConfirmDialogOpen(false)}
                confirmDialog={navigateManageBooking}
                content={translatedTextsObject.dialogDiscardBookingContent}
                okMsg={uiTranslatedTextsObject.ok}
                cancelMsg={uiTranslatedTextsObject.cancel}
                title={translatedTextsObject.dialogDiscardBookingTitle}
            />
        </Box>
    )
}

const ManageBookingAmendReviewFormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    FormFields: FormFields
    // toClientDataFormat: toClientDataFormat,
    // toServerDataFormat: toServerDataFormat
})


export default ManageBookingAmendReviewFormProperties