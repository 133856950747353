import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import ManageBookingFormProperties from './ManageBookingFormProperties'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_FreightBooking'

const {
  form: { CngViewForm }
} = components

function MyBookingPage({ showNotification }) {
  const { id } = useParams()
  const history = useHistory()

  function onSubmit(data) {
    // console.log('submityyy', data)

    history.push('/')
    history.replace({ pathname: pathMap.MANAGE_BOOKING, state: data })
  }

  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <ManageBookingFormProperties.FormFields
          disabled={false}
          showNotification={showNotification}
        />
      }
      formikProps={{
        ...ManageBookingFormProperties.formikProps,
        onSubmit: onSubmit
      }}
      // toClientDataFormat={PollingFormProperties.toClientDataFormat}
      // toServerDataFormat={PollingFormProperties.toServerDataFormat}
    />
  )
}

export default MyBookingPage
