import { Box, Button, Card, Divider, Grid } from '@material-ui/core'
import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { Yup, components, constants, useServices } from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'

import AmendScheduleVesselScheduleTableComponent from './AmendScheduleVesselScheduleTableComponent'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelButton from 'src/components/button/CancelButton.js'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls.js'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import PortViewField from 'src/components/field/PortViewField'
import Utils from 'src/views/common/utils/Utils'
import VesselSearchDialogContext from './VesselSearchDialogContext.js'
import check from 'check-types'

const {
  form: {
    CngForm,
    field: {
      CngTextField
    },
  },
  table: { useDefaultNotification }
} = components
const { FormState } = constants

function VesselSearchForm(props) {
  const { booking } = props
  // alert(JSON.stringify(booking))
  const { fetchRecords } = useServices()
  const dialogContext = useContext(VesselSearchDialogContext)
  const {
    closeDialog,
    showNotification,
    form: { isSubmitting, setSubmitting }
  } = dialogContext
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const translatedTextsObject = FreightBookingTranslationText()
  const [loading, setLoading] = useState(false)

  function onSubmit(data) {
    //on submit
  }



  function FormBody({ closeDialog, isSubmitting, booking }) {

    const translatedTextsObject = FreightBookingTranslationText()


    return (
      <Box m={1.5}>
        <Box mb={2}>
          <Card>
            <Grid container xs={12} sm={12}>
              <Grid xs={8} sm={8}>
                <Grid container xs={12} sm={12}>
                  <Grid xs={5} sm={5}>
                    <Box className="view-card-content">
                      <Box className="view-card-content-label">
                        {translatedTextsObject.portOfLoading}
                      </Box>
                      <Box className="view-card-content-value">
                        <PortViewField portCode={booking.polCode} />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid xs sm>
                    <Grid container
                      justify="center"
                      alignItems="center"
                      style={{ height: "100%" }}>
                      <Box className="view-card-content">
                        <Box className="view-card-content-value" >
                          <ArrowForwardIcon />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid xs={5} sm={5}>
                    <Box className="view-card-content">
                      <Box className="view-card-content-label" >
                        {translatedTextsObject.portOfDischarge}
                      </Box>
                      <Box className="view-card-content-value">
                        <PortViewField portCode={booking.podCode} />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid xs={1} sm={1}>

                  </Grid>
                </Grid>

              </Grid>
              <Grid xs={4} sm={4}>
                <Grid container xs={12} sm={12}>
                  <Grid xs={4} sm={4}>
                    <Box className="view-card-content">
                      <Box className="view-card-content-label">
                        {translatedTextsObject.cargoReadyDate}
                      </Box>
                      <Box className="view-card-content-value">
                        {Utils.formatDate(booking.cargoReadyDate, Utils.FORMAT_DATE, Utils.DASH)}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid xs sm>
                    <Grid container
                      justify="center"
                      alignItems="center"
                      style={{ height: "100%" }}>
                      <Box className="view-card-content">
                        <Box className="view-card-content-value">
                          <ArrowForwardIcon />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid xs={5} sm={5}>
                    <Box className="view-card-content">
                      <Box className="view-card-content-label">
                        {translatedTextsObject.cargoDeliveryDate}
                      </Box>
                      <Box className="view-card-content-value">
                        {Utils.formatDate(booking.cargoDeliveryDate, Utils.FORMAT_DATE, Utils.DASH)}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Box>
        <Divider light />
        <Box my={2}>
          <Card >
            <Grid>
              <AmendScheduleVesselScheduleTableComponent booking={booking} setLoading={setLoading} closeDialog={closeDialog} />
            </Grid>
          </Card>
        </Box>
        <Box my={1}>

        </Box>


      </Box>
    )
  }

  return (
    <Formik
      validateOnChange={false}
      validationSchema={validationSchema}
      initialValues={{
        ...initialValues
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Form>
        <FormBody closeDialog={closeDialog} isSubmitting={isSubmitting} booking={booking} />
      </Form>
    </Formik>
  )
}

const initialValues = Object.freeze({

})


const validationSchema = Yup.object({

})

export default VesselSearchForm
