import { Box, Button, Grid } from '@material-ui/core'

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import React from 'react'
import FreightBookingTranslationText from '../../shared/FreightBookingTranslationText'

function ManageBookingAmendReviewButtonComponent(props) {

    const translatedTextsObject = CalistaUiComponentTranslationText()
    const frbTranslationTextObject = FreightBookingTranslationText()

    return (
        <Box pl={5} pr={5} pt={4} pb={4}>
            <Grid container xs={12} sm={12}>
                <Grid xs={6} sm={6} justify="flex-start">
                    <Button variant="contained"
                        variant="contained"
                        color="secondary"
                        onClick={() => { props.onDiscard() }}
                        classes={{ root: 'ng-button-filled-secondary' }}
                    >
                        {translatedTextsObject.cancel}
                    </Button>
                </Grid>
                <Grid container xs={6} sm={6} justify="flex-end" spacing={2}>

                    <Box pr={2}>
                        <Button variant="contained"
                            className="button-blue originalText"
                            onClick={() => { props.onEdit() }}
                        >
                            {translatedTextsObject.edit}
                        </Button>
                    </Box>
                    <Box >
                        <Button variant="contained"
                            className="button-light-green originalText"
                            onClick={() => { props.onSubmitBooking() }}
                        >
                            {frbTranslationTextObject.approveBooking}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ManageBookingAmendReviewButtonComponent